import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";
import IconPlayArrow from "~/img/icons/play_arrow.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export default function Enga3Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 3",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-3">
        <FirstScreen
          visual="/images/engagements/page-engagement-3-visual.jpg"
          visualMobile="/images/engagements/page-engagement-3-visual-mobile.jpg"
          title="Plus de lumière, moins de bruit"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-confort-d-ete.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
        </section>

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien-être</span>
            <span>Pour votre santé</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est vous garantir des
            appartements avec des surfaces vitrées supérieures au standard et un
            confort acoustique renforcé.
          </p>
        </div>

        <div class="media-part">
          <div class="visual">
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-3-video.jpg",
                `size:690:442/resizing_type:fill`,
              )}
              alt=""
              height="240"
              width="375"
              loading="lazy"
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/4UBECUaGS5o" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <p class="blockquote-introduction">
              La qualité de l’environnement sonore expliquée par Dominique
              Bidou, Président d’Honneur de l'association HQE - GBC.
            </p>
            <blockquote>
              <p>
                Au-delà même du silence, la qualité de l’environnement sonore
                est quelque chose de très important : le bruit fait partie de la
                vie, il ne faudrait pas le supprimer.
              </p>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Plus de lumière avec des surfaces vitrées plus importantes</h3>
          <p>
            Sommeil, humeur, mémoire, capacités d’apprentissage, immunité… La
            liste des effets biologiques de la lumière ne cesse de s’allonger.
            La luminosité des pièces principales, et notamment des séjours, est
            primordiale.
          </p>
          <p>
            Dans tous ses nouveaux projets, Cogedim propose 20% de surface de
            vitrage de plus que la réglementation dans les séjours.
          </p>
          <p class="excerpt">
            <strong>+20%</strong> de surfaces vitrées dans nos appartements
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-3-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Une meilleure isolation phonique</h3>
          <p>
            Pour lire, travailler, dormir, étudier, écouter de la musique...
            tous nos appartements bénéficient d’un renforcement du confort
            acoustique entre zones de jour et zones de nuit, par la mise en
            oeuvre de cloisons de 7&nbsp;cm avec laine minérale incorporée,
            ainsi que d’une porte de distribution isophonique à partir du 3
            pièces.
          </p>
          <p>
            Nous posons systématiquement une chape isophonique, avec coulage
            avant la pose des cloisons, pour faciliter les modifications
            ultérieures du cloisonnement, sans altérer les performances
            acoustiques.
          </p>
          <p class="excerpt">
            <strong>100%</strong> de nos appartements bénéficient d'un confort
            acoustique renforcé
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-3-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}
