import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga3Page from "~/components/Enga/Enga3Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est vous garantir des appartements avec des surfaces vitrées supérieures au standard et un confort acoustique renforcé.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Plus de lumière, moins de bruit</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga3Page />
    </>
  );
}
